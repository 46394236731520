import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';
import uuid from 'uuid/v4';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	AISummaryStreamingState,
	type StreamError,
	useAISummaryStreaming,
	useConfluencePageAri,
} from '@atlassian/ai-summary';
import { usePopupDwellTime } from '@atlassian/popup-dwell-time';
import {
	AIEventsInstrumentationProvider,
	useAIEventsInstrumentation,
} from '@atlassian/ai-analytics';
import type { AIEventsInstrumentationConfig } from '@atlassian/ai-analytics';
import { AIStreamLoading, AIBorder } from '@atlassian/ai-components';

import type { SummaryAnalyticsPropertiesType } from '@confluence/quick-summary';
import { SmartsSummaryErrorComponent } from '@confluence/quick-summary';
import { useSessionData } from '@confluence/session-data';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import {
	AI_CONTENT_CATCHUP_EXPERIENCE,
	ExperienceSuccess,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { createSingleQueryParamHook } from '@confluence/route-manager';

import { PageCatchupComponent } from './platform-ui/PageCatchupComponent';
import { PageCatchupMetadataQuery } from './PageCatchupMetadataQuery.graphlql';
import { CatchupContentType } from './__types__/PageCatchupMetadataQuery';
import type {
	PageCatchupMetadataQuery as PageCatchupMetaDataResponseType,
	PageCatchupMetadataQueryVariables,
} from './__types__/PageCatchupMetadataQuery';
import { mapToEditors } from './data/mapToEditors';
import { mapToFormattedDate } from './data/mapToFormattedDate';
import { AI_CONTENT_CATCHUP_INTERACTION_METRIC } from './perf.config';
import { useGetVersionHistoryLink } from './useGetVersionHistoryLink';
import type {
	PageCatchupVersionDiffMetadataForContentQuery as PageCatchupVersionDiffMetadataResponseType,
	PageCatchupVersionDiffMetadataForContentQueryVariables,
} from './__types__/PageCatchupVersionDiffMetadataForContentQuery';
import { PageCatchupVersionDiffMetadataForContentQuery } from './PageCatchupVersionDiffMetadataForContentQuery.graphql';

const useOpenContentCatchupParam = createSingleQueryParamHook('openContentCatchup');

type PageCatchupWrapperComponentProps = {
	contentId: string;
	contentType: string;
	spaceKey: string;
	source?: string;
	selectedVersions?: Set<any>;
};

export const PageCatchupWrapperComponent: FC<PageCatchupWrapperComponentProps> = ({
	contentId,
	contentType,
	spaceKey,
	source,
	selectedVersions,
}) => {
	const { locale } = useIntl();
	const { cloudId } = useSessionData();
	const { pageAri } = useConfluencePageAri(cloudId, contentId, contentType);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [aiInteractionId] = useState(uuid());
	const experienceTracker = useContext(ExperienceTrackerContext);
	const catchUpContentRef = useRef<HTMLDivElement | null>(null);
	const { getDwellTime } = usePopupDwellTime(catchUpContentRef);
	const [dateInMs] = useState(Date.now());
	const [hideMetadata, setHideMetadata] = useState(true);
	const analyticsProperties: SummaryAnalyticsPropertiesType = {
		source: 'pageCatchup',
		additionalAnalyticsAttributes: {
			subSource: source,
		},
	};

	const originalContentVersion = selectedVersions ? Math.min(...Array.from(selectedVersions)) : 0;
	const revisedContentVersion = selectedVersions ? Math.max(...Array.from(selectedVersions)) : 0;
	const hasSelectedVersions =
		selectedVersions && selectedVersions.size > 1 && source === 'pageHistory';

	const isStreamingDone = useRef(false);

	const {
		trackAIInteractionInit,
		trackAIResultView,
		trackAIResultError,
		trackAIResultAction,
		trackAIInteractionDismiss,
	} = useAIEventsInstrumentation();

	const hasQueryParamOpenSet = useOpenContentCatchupParam() === 'true';

	const contentTypeEnum =
		contentType === 'page' ? CatchupContentType.PAGE : CatchupContentType.BLOGPOST;

	const { data, error: defaultMetaDataError } = useQuery<
		PageCatchupMetaDataResponseType,
		PageCatchupMetadataQueryVariables
	>(PageCatchupMetadataQuery, {
		variables: { contentId, contentType: contentTypeEnum, endTimeMs: dateInMs },
		skip: hasSelectedVersions,
	});

	const { data: versionDiffMetadata, error: versionDiffMetadataError } = useQuery<
		PageCatchupVersionDiffMetadataResponseType,
		PageCatchupVersionDiffMetadataForContentQueryVariables
	>(PageCatchupVersionDiffMetadataForContentQuery, {
		variables: {
			contentId,
			contentType: contentTypeEnum,
			originalContentVersion,
			revisedContentVersion,
		},
		skip: !hasSelectedVersions,
	});

	const metaDataError = defaultMetaDataError || versionDiffMetadataError;

	const isVersionDiffEmpty =
		versionDiffMetadata?.catchupVersionDiffMetadataForContent?.isDiffEmpty ?? false;

	const versionDiffUsers = versionDiffMetadata?.catchupVersionDiffMetadataForContent?.users ?? [];
	const versionDiffEditors = mapToEditors(versionDiffUsers);

	const setIsStreamingDone = () => {
		isStreamingDone.current = true;
	};

	const onStart = () => {
		experienceTracker.start({
			name: AI_CONTENT_CATCHUP_EXPERIENCE,
		});
		AI_CONTENT_CATCHUP_INTERACTION_METRIC.start();
		trackAIInteractionInit();
	};

	const onDone = () => {
		setIsStreamingDone();
	};

	const onError = (reason: StreamError) => {
		AI_CONTENT_CATCHUP_INTERACTION_METRIC.stop();
		trackAIResultError({
			aiErrorCode: reason.message.status_code!!,
			aiErrorMessage: reason.message.message_template,
		});
		setIsStreamingDone();
	};

	const onShowMoreClick = () => {
		trackAIResultAction('showMoreClicked');
	};

	const agentNamedId = hasSelectedVersions
		? 'content_catchup_version_diff_agent'
		: 'content_catchup_agent';

	const {
		content,
		state,
		error: streamSummaryError,
	} = useAISummaryStreaming({
		ari: pageAri ?? '',
		agentNamedId,
		product: 'confluence',
		experienceId: 'content-catchup',
		locale,
		delayChunkUpdate: { gap: 10, time: 20 },
		endTimeMs: dateInMs,
		...(hasSelectedVersions && {
			updateType: 'BETWEEN_VERSIONS',
			selectedVersions: {
				original_content_version: Math.min(...Array.from(selectedVersions)),
				revised_content_version: Math.max(...Array.from(selectedVersions)),
			},
		}),
		onStart,
		onError,
		onDone,
	});

	useEffect(() => {
		return () => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'dwelled',
					actionSubject: 'pageCatchup',
					source: source ?? 'page',
					actionSubjectId: 'pageCatchupDialog',
					attributes: {
						contentId,
						dwellTime: getDwellTime(),
						aiInteractionId,
						hasQueryParamOpenSet,
					},
				},
			}).fire();

			if (!isStreamingDone.current) {
				trackAIInteractionDismiss();
			}
		};
		// we only want to run this effect on un-mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (state === AISummaryStreamingState.FinalResponse && content) {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'pageCatchupDialog',
					attributes: {
						contentId,
						aiInteractionId,
						hasQueryParamOpenSet,
					},
				},
			}).fire();
			trackAIResultView();
		}
	}, [
		content,
		contentId,
		state,
		createAnalyticsEvent,
		aiInteractionId,
		hasQueryParamOpenSet,
		trackAIResultView,
	]);

	useEffect(() => {
		if (state === AISummaryStreamingState.FinalResponse && content) {
			setHideMetadata(false);
			AI_CONTENT_CATCHUP_INTERACTION_METRIC.stop();
		}
	}, [state, content]);

	const lastVisitedOnDate = mapToFormattedDate(
		data?.catchupEditMetadataForContent?.lastVisitTimeISO,
		locale,
	);

	const hasVersionChangedSinceLastVisit =
		data?.catchupEditMetadataForContent?.hasVersionChangedSinceLastVisit ?? false;

	const users = data?.catchupEditMetadataForContent?.users ?? [];

	const defaultEditors = mapToEditors(users);

	const editors = source === 'pageHistory' ? versionDiffEditors : defaultEditors;

	const pageVersion = data?.content?.nodes?.[0]?.version?.number ?? -1;

	const { versionHistoryLink } = useGetVersionHistoryLink(contentId, spaceKey, pageVersion);

	const onVersionHistoryLinkClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'pageCatchup',
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'pageCatchupVersionHistoryLink',
				attributes: {
					contentId,
					aiInteractionId,
					hasQueryParamOpenSet,
				},
			},
		}).fire();
		trackAIResultAction('versionHistoryLinkClick');
	}, [contentId, createAnalyticsEvent, aiInteractionId, hasQueryParamOpenSet, trackAIResultAction]);

	const renderContent = () => {
		switch (state) {
			case AISummaryStreamingState.Initialization:
				return <AIStreamLoading />;
			case AISummaryStreamingState.Error:
				return (
					<SmartsSummaryErrorComponent
						analyticsProperties={analyticsProperties}
						errorFromApolloOrStream={streamSummaryError}
						contentId={contentId}
						experienceName={AI_CONTENT_CATCHUP_EXPERIENCE}
					/>
				);
			case AISummaryStreamingState.FinalResponse:
			default:
				return (
					<PageCatchupComponent
						contentId={contentId}
						content={content}
						contentType={contentType}
						spaceKey={spaceKey}
						lastVisitedOnDate={lastVisitedOnDate}
						editors={editors}
						ref={catchUpContentRef}
						hideMetadata={hideMetadata}
						versionHistoryLink={versionHistoryLink}
						onVersionHistoryLinkClick={onVersionHistoryLinkClick}
						hasVersionChangedSinceLastVisit={hasVersionChangedSinceLastVisit}
						onShowMoreClick={onShowMoreClick}
						source={source}
						isVersionDiffEmpty={isVersionDiffEmpty}
					/>
				);
		}
	};

	return (
		<>
			{metaDataError && <ErrorDisplay error={metaDataError} />}
			<AIBorder loading={state === AISummaryStreamingState.Initialization}>
				{renderContent()}
				{state === AISummaryStreamingState.FinalResponse && (
					<ExperienceSuccess name={AI_CONTENT_CATCHUP_EXPERIENCE} attributes={{ source }} />
				)}
			</AIBorder>
		</>
	);
};

export const PageCatchupComponentWithErrorBoundary = (props: PageCatchupWrapperComponentProps) => {
	const aiAnalyticsConfig: AIEventsInstrumentationConfig = {
		product: 'confluence',
		subproduct: 'confluence',
		source: props.source ?? 'confluencePage',
		aiFeatureName: 'pageCatchup',
		proactiveGeneratedAI: 0,
		userGeneratedAI: 1,
	};

	return (
		<ErrorBoundary attribution={Attribution.SMARTS}>
			<AIEventsInstrumentationProvider config={aiAnalyticsConfig}>
				<PageCatchupWrapperComponent {...props} />
			</AIEventsInstrumentationProvider>
		</ErrorBoundary>
	);
};
