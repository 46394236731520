import type { FC } from 'react';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';

const i18n = defineMessages({
	editorsNameListText: {
		id: 'page-catchup.editors.name.list.text',
		defaultMessage:
			'{editorsCount, plural, one {{firstEditor} has} =2 {{firstEditor} and {secondEditor} have} =3 {{firstEditor}, {secondEditor}, and {thirdEditor} have} other {{firstEditor}, {secondEditor}, {thirdEditor} and {remainingEditorCount} others have}} edited the page',
		description: 'Text to show a list of users for an edited page.',
	},
	editorsNameListTextNoVerbiage: {
		id: 'page-catchup.editors.name.list.text.no.verbiage',
		defaultMessage:
			'{editorsCount, plural, one {{firstEditor}} =2 {{firstEditor} and {secondEditor}} =3 {{firstEditor}, {secondEditor}, and {thirdEditor}} other {{firstEditor}, {secondEditor}, {thirdEditor} and {remainingEditorCount} others}}',
		description: 'Text to show a list of users for an edited page.',
	},
});

export type Editor = {
	name: string;
	src?: string;
};

type EditorsNameListComponentProps = {
	editors: Editor[];
	showNoVerbiage?: boolean;
};

export const EditorsNameListComponent: FC<EditorsNameListComponentProps> = ({
	editors,
	showNoVerbiage,
}) => {
	const editorsCount = editors.length;

	return (
		<Box
			testId="editors-name-list"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({ fontSize: '11px' })}
		>
			<FormattedMessage
				{...(showNoVerbiage ? i18n.editorsNameListTextNoVerbiage : i18n.editorsNameListText)}
				values={{
					editorsCount,
					firstEditor: editorsCount > 0 ? editors[0].name : null,
					secondEditor: editorsCount > 1 ? editors[1].name : null,
					thirdEditor: editorsCount > 2 ? editors[2].name : null,
					remainingEditorCount: editorsCount - 3,
				}}
			/>
		</Box>
	);
};
