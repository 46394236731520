import Markdown from 'markdown-to-jsx';
import React, { forwardRef } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { styled } from '@compiled/react';

import { Box, Flex, Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import AvatarGroup from '@atlaskit/avatar-group';
import Link from '@atlaskit/link';

import { AIContentBlur, AITelepointerWrapper } from '@atlassian/ai-components';

import { AISummaryFooter, FeedbackCollectorPropertiesProvider } from '@confluence/quick-summary';

import { EditorsNameListComponent, type Editor } from './EditorsNameListComponent';
import { VersionDiffEmptyComponent } from './VersionDiffEmptyComopnent';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ScrollableBody = styled.div({
	font: token('font.body.large'),
	lineHeight: '24px',
	overflowY: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&::-webkit-scrollbar': {
		width: token('space.100', '8px'),
		background: 'transparent',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&::-webkit-scrollbar-thumb': {
		borderRadius: token('space.100', '8px'),
		backgroundColor: token('color.background.neutral'),
		'&:hover': {
			backgroundColor: token('color.background.neutral.hovered'),
		},
	},
});

const HeaderStyles = xcss({
	backgroundColor: 'color.background.accent.gray.subtlest',
	borderRadius: '4px',
	padding: 'space.100',
});

const StackStyles = xcss({
	overflowWrap: 'break-word',
	whiteSpace: 'normal',
});

const i18n = defineMessages({
	lastVisitedOn: {
		id: 'page-catchup.last-visited-on-text',
		defaultMessage: 'Changes since you last visited on {lastVisitedOnDate}.',
		description: 'Text for when the user last visited the content',
	},
	firstTimeVisit: {
		id: 'page-catchup.first-time-visit-text',
		defaultMessage:
			'Since this is your first time visiting this page, you’re seeing a page summary instead of a summary of changes.',
		description:
			'Text to show when a user views the feature but is visiting the page for the first time',
	},
	noChangesSinceLastVisit: {
		id: 'page-catchup.no-changes-since-last-visit-text',
		defaultMessage:
			'There haven’t been any changes since your last visit on {lastVisitedOnDate}. We’ve generated this page summary instead to help refresh your memory.',
		description:
			'Text to show when a user views the feature but is visiting the page for the first time',
	},
	fullVersionHistoryLinkText: {
		id: 'page-catchup.full.version.history.link.text',
		defaultMessage: 'See changes in version history',
		description: 'Link text to direct a user to the full version history page',
	},
	changesBetweenVersionsText: {
		id: 'page-catchup.changes.between.versions.text',
		defaultMessage: 'Changes between selected versions',
		description:
			'Text to show when a user is viewing the diff between two selected versions of a page',
	},
});

type PageCatchupComponentProps = {
	contentId: string;
	content: string;
	contentType: string;
	spaceKey: string;
	lastVisitedOnDate: string | undefined;
	editors: Editor[];
	hideMetadata?: boolean;
	versionHistoryLink?: string | undefined;
	onVersionHistoryLinkClick?: () => void;
	hasVersionChangedSinceLastVisit?: boolean;
	onShowMoreClick?: () => void;
	source?: string;
	isVersionDiffEmpty?: boolean;
};

export const PageCatchupComponent = forwardRef<HTMLDivElement, PageCatchupComponentProps>(
	(
		{
			contentId,
			content,
			contentType,
			spaceKey,
			lastVisitedOnDate,
			editors,
			hideMetadata,
			versionHistoryLink,
			onVersionHistoryLinkClick,
			hasVersionChangedSinceLastVisit,
			onShowMoreClick,
			source,
			isVersionDiffEmpty,
		},
		catchUpContentRef,
	) => {
		const getHeaderText = () => {
			if (!hasVersionChangedSinceLastVisit && lastVisitedOnDate) {
				return (
					<FormattedMessage {...i18n.noChangesSinceLastVisit} values={{ lastVisitedOnDate }} />
				);
			} else if (hasVersionChangedSinceLastVisit && lastVisitedOnDate) {
				return <FormattedMessage {...i18n.lastVisitedOn} values={{ lastVisitedOnDate }} />;
			} else if (source === 'pageHistory' && !isVersionDiffEmpty) {
				return <FormattedMessage {...i18n.changesBetweenVersionsText} />;
			} else {
				return <FormattedMessage {...i18n.firstTimeVisit} />;
			}
		};

		if (source === 'pageHistory' && isVersionDiffEmpty) {
			return <VersionDiffEmptyComponent />;
		}

		return (
			<FeedbackCollectorPropertiesProvider entrypointId="d4c9238b-d3d6-4298-83d9-10f6bddd4f2b">
				<Stack xcss={StackStyles}>
					<Box padding="space.200">
						<Stack space="space.200">
							<Stack xcss={HeaderStyles} space="space.100">
								<Box>{getHeaderText()}</Box>
								{((lastVisitedOnDate && hasVersionChangedSinceLastVisit) ||
									source === 'pageHistory') &&
									editors.length > 0 && (
										<Inline space="space.050" alignBlock="center">
											<AvatarGroup size="small" data={editors} testId="page-catchup" maxCount={4} />
											<EditorsNameListComponent editors={editors} />
										</Inline>
									)}
							</Stack>
							{(source !== 'pageHistory' || !isVersionDiffEmpty) && (
								<Flex
									direction="column"
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
									xcss={xcss({
										maxHeight: '350px',
										gap: 'space.200',
										height: '100%',
									})}
								>
									<AIContentBlur
										shouldOpenInCollapsedView={
											(!lastVisitedOnDate || !hasVersionChangedSinceLastVisit) &&
											source !== 'pageHistory'
										}
										onShowMore={onShowMoreClick}
										summaryLineHeight={80}
									>
										<ScrollableBody ref={catchUpContentRef}>
											<AITelepointerWrapper hideTelepointer={!hideMetadata}>
												<Markdown>{content}</Markdown>
											</AITelepointerWrapper>
										</ScrollableBody>
									</AIContentBlur>
								</Flex>
							)}
							{versionHistoryLink && !hideMetadata && hasVersionChangedSinceLastVisit && (
								<Link href={versionHistoryLink} onClick={onVersionHistoryLinkClick} target="_blank">
									<FormattedMessage {...i18n.fullVersionHistoryLinkText} />
								</Link>
							)}
						</Stack>
					</Box>
					{!hideMetadata && (
						<AISummaryFooter
							analyticsProperties={{ source: 'pageCatchup' }}
							contentId={contentId}
							spaceKey={spaceKey}
							contentType={contentType}
							summaryText={content}
						/>
					)}
				</Stack>
			</FeedbackCollectorPropertiesProvider>
		);
	},
);
